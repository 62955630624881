import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SEO from "../components/SEO/SEO";
import Section from "../components/Section";
import Wrapper from "../components/Wrapper";
import Lists from "../components/Lists";
import Link from "../components/Link";
import Button from "../components/Button";
import Transitions from "../components/Transitions";
import Image from "../components/Image/Image.Static";

import Form from "../components/Form/Form.Competition.tsx";

import TehetsegkutatoSVG from "@images/svg/tehetsegkutato.inline.svg";
import DecideSVG from "@images/svg/decide.inline.svg";

const ArticlesHero: React.FC<{}> = () => {
  return (
    <Layout>
        <SEO slug={`/tehetsegkutato`} title={`iFaktor Tehetségkutató Jelentkezés`} isOrganization isSubPage noindex />
        <Wrap>
            <LandingSection flex id="landing">
                <LandingWrapper left>
                    <Transitions.JS.FadeInUpGroup>
                        <Header>iFaktor Agency Tehetségkutató</Header>
                        <Subtitle>
                        Jelentkezz Magyarország elsőszámú közösségi média tehetségkutatójába és nyerd meg értékes nyereményeinket!
                        </Subtitle>
                        <Button as={Link} to="/tehetsegkutato#jelentkezes" secondary>Jelentkezem</Button>
                    </Transitions.JS.FadeInUpGroup>
                </LandingWrapper>
                
                <Transitions.JS.FadeInGroup duration={1000} delay={300} contents removeStylesAfter>
                    <InfluencersIcon right />
                </Transitions.JS.FadeInGroup>
            </LandingSection>

            <Section>
                <Wrapper>
                    <FlexContainer reverse>
                        <Transitions.JS.FadeInGroup duration={1000} contents as={FlexItem} removeStylesAfter>
                            {/* <DecideIcon left /> */}
                            <ImageContainer>
                            <Image src={`influencerek.png`} alt={`Whisperton, Makka Adrienn, Lady Szomjas, Hollósi Jázmin`} />
                            </ImageContainer>
                        </Transitions.JS.FadeInGroup>
                        <Transitions.JS.FadeInUpGroup as={FlexItem}>
                            <Title>Az iFaktor első tehetségkutatója</Title>
                            <Description>
                            A TikTok-on éled az életed és szeretnéd megmutatni a világnak, ki vagy? Akkor ez a verseny neked szól! Jelentkezz Magyarország elsőszámú iFaktor tehetségkutatójába és nyerd meg értékes nyereményeinket!
                            </Description>
                            <Button as={Link} to="/tehetsegkutato#jelentkezes">Jelentkezem</Button>
                        </Transitions.JS.FadeInUpGroup>
                    </FlexContainer>
                </Wrapper>
            </Section>

            <LandingSection form id="jelentkezes">
                <Wrapper>
                    <FlexContainer>
                        <Transitions.JS.FadeInUpGroup as={FlexItem}>
                            <Title custom>Jelentkezz a Tehetségkutatóra!</Title>
                            <Description>
                            Csupán annyi a dolog, hogy jelentkezel és lehet, hogy te nyered meg az:
                            <Lists.ul>
                                <li>1 db Xiaomi Mi Dual 5G okostelefon</li>
                                <li>1 db Xiaomi electric scooter</li>
                                <li>1 db Xiaomi okosóra</li>
                                <li>1 db két főre szóló, két éjszakás wellness hétvége a Dráva Hotel Thermal Resort-ban</li>
                                <li>100.000 Ft készpénz</li>
                            </Lists.ul>
                            <u>Jelentkezz és legyél Te Magyarország első közösségi média tehetségkutatójának nyertese!</u>
                            </Description>
                        </Transitions.JS.FadeInUpGroup>
                        <ContactContainer as={FlexItem}>
                            <FormOverlay />
                            <SmallTitle>Jelentkezés</SmallTitle>
                            <Form />
                        </ContactContainer>
                    </FlexContainer>
                </Wrapper>
            </LandingSection>
        </Wrap>
    </Layout>
  );
};

export default ArticlesHero;

const Wrap = styled.div`
    ${tw`relative w-full h-full`};
`;

const LandingSection = styled(Section)<{form?: boolean; flex?: boolean}>`
    ${tw`bg-gray-200 top-16 md:top-0`};

    ${p => p.form && tw`mb-32 md:-mb-32`};
`;

const LandingWrapper = styled(Wrapper)`
    ${tw`md:w-7/12 lg:w-6/12`};
`;

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.accent};
    }
`;

const Header = styled.h1`
    ${tw`text-4xl font-semibold leading-none tracking-tight md:text-5xl`};

    ${spanColorAccent};
`;

const Subtitle = styled.p`
    ${tw`my-8 mb-4 text-lg text-gray-700 md:text-xl`};
`;

const ContentSVG = css`
    ${tw`w-8/12 h-full max-w-full md:absolute md:w-4/12 lg:w-3/12 mt-6`};
`;

const RightSVG = css`
    ${tw`md:right-32 lg:right-40 xl:right-64`};
`;

const LeftSVG = css`
    ${tw`md:left-32 lg:left-40 xl:left-64`};
`;

const InfluencersIcon = styled(TehetsegkutatoSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};

    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const DecideIcon = styled(DecideSVG)<{left?: boolean; right?: boolean}>`
    ${ContentSVG};

    ${p => p.left && LeftSVG};
    ${p => p.right && RightSVG};
`;

const Title = styled.h2<{center?: boolean; secondaryAccent?: boolean; custom?: boolean}>`
    ${tw`text-3xl font-semibold leading-none tracking-tight md:text-5xl mt-12 md:mt-0`};
    ${p => p.custom && tw`text-center md:text-left`};
    
    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
`;

const Description = styled.p<{intro?: boolean}>`
    ${tw`my-8 text-base leading-7 text-center text-gray-700 text-left md:text-lg`};
    & ul {
        ${tw`text-left`};
    }
    
    ${p => p.intro && tw`max-w-xl mx-auto text-center md:text-center`};
`;

const FlexContainer = styled.div<{ justifyNormal?: boolean, sideBar?: boolean; reverse?: boolean; }>`
    ${tw`flex flex-col items-center justify-between md:flex-row`};
    ${p => p.reverse && tw`flex-col-reverse`};
`;

const FlexItem = styled.div`
    ${tw`flex-1 w-full max-w-2xl m-auto md:m-8 md:first-of-type:ml-0 md:last-of-type:mr-0`};
`;

const ContactContainer = styled.div`
    ${tw`flex-auto max-w-xl px-6 py-4 m-auto bg-white rounded shadow-2xl`};
`;

const SmallTitle = styled.h3<{center?: boolean; secondaryAccent?: boolean}>`
    ${tw`mt-4 text-2xl font-semibold leading-none tracking-tight md:text-3xl text-center`};

    ${spanColorAccent};
    ${p => p.secondaryAccent && `span { color: ${p.theme.colors.secondaryAccent}; }`};
`;

const ImageContainer = styled.div`
    transition: ${p => p.theme.transitionValue};
    ${tw`w-full h-full max-w-xl shadow-xl rounded-md overflow-hidden transform scale-95 hover:scale-100 hover:shadow-2xl mt-8 md:mt-0`};
`;

const FormOverlay = styled.div`
    ${tw`absolute w-full h-full bg-gray-300 opacity-75 z-20 top-0 left-0`};
`;